import * as React from 'react';
import { ScriptStrategy } from 'gatsby';
import Script from 'analytics/components/Script';
import useBingPixelId from 'analytics/hooks/useBingPixelId';

export default function BingPixel() {
    const pixelId = useBingPixelId();

    if (pixelId) {
        /* eslint-disable max-len */
        return (
            <Script
                id="bing-pixel"
                strategy={ScriptStrategy.idle}
                content={`(function(w, d, t, r, u) {
    var f, n, i;
    w[u] = w[u] || [], f = function() {
        var o = { ti: '${pixelId}' };
        o.q = w[u], w[u] = new UET(o), w[u].push('pageLoad');
    }, n = d.createElement(t), n.src = r, n.async = 1, n.onload = n.onreadystatechange = function() {
        var s = this.readyState;
        s && s !== 'loaded' && s !== 'complete' || (f(), n.onload = n.onreadystatechange = null);
    }, i = d.getElementsByTagName(t)[0], i.parentNode.insertBefore(n, i);
})(window, document, 'script', '//bat.bing.com/bat.js', 'uetq');`}
            />
        );
    }

    return null;
}
