import * as React from 'react';
import type { Maybe, AdrollSettings } from '@types/gatsby-types';
import { graphql, useStaticQuery, ScriptStrategy } from 'gatsby';
import Script from 'analytics/components/Script';

const query = graphql`
    query YelpPixelQuery {
        settings {
            adroll {
                advId
                pixelId
            }
        }
    }
`;

type YelpPixelQuery = {
    settings: Maybe<{
        adroll: Maybe<Pick<AdrollSettings, 'advId' | 'pixelId'>>;
    }>;
};

export default function YelpPixel() {
    const { settings } = useStaticQuery<YelpPixelQuery>(query);
    const advId = settings?.adroll?.advId;
    const pixelId = settings?.adroll?.pixelId;

    if (pixelId) {
        return (
            <Script
                strategy={ScriptStrategy.idle}
                timeoutMs={2000}
                content={`
    adroll_pix_id = "${pixelId}"
    adroll_adv_id = "${advId}"
    adroll_version = "2.0";

    (function(w, d, e, o, a) {
        w.__adroll_loaded = true;
        w.adroll = w.adroll || [];
        w.adroll.f = [ 'setProperties', 'identify', 'track' ];
        var roundtripUrl = "https://s.adroll.com/j/${advId}/roundtrip.js";
        for (a = 0; a < w.adroll.f.length; a++) {
            w.adroll[w.adroll.f[a]] = w.adroll[w.adroll.f[a]] || (function(n) {
                return function() {
                    w.adroll.push([ n, arguments ])
                }
            })(w.adroll.f[a])
        }

        e = d.createElement('script');
        o = d.getElementsByTagName('script')[0];
        e.async = 1;
        e.src = roundtripUrl;
        o.parentNode.insertBefore(e, o);
    })(window, document);
    adroll.track("pageView");`}
            />
        );
    }

    return null;
}
