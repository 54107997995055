import * as React from 'react';
import AccessiBe from '../AccessiBe';
import AdaChatBot from '../AdaChatBot';
import BingPixel from '../BingPixel';
import FacebookPixel from '../FacebookPixel';
import HotjarPixel from '../HotjarPixel';
import NextdoorPixel from '../NextdoorPixel';
import RedditPixel from '../RedditPixel';
import TiktokPixel from '../TiktokPixel';
import YelpPixel from '../YelpPixel';
import ConvertABScript from '../ConvertABScript';

type PostBodyComponentProps = {
    isBookingPage: boolean;
};

export default function PostBodyComponents({
    isBookingPage,
}: PostBodyComponentProps) {
    return (
        <>
            <ConvertABScript />
            <AccessiBe />
            <AdaChatBot isBookingPage={isBookingPage} />
            <BingPixel />
            <FacebookPixel />
            <HotjarPixel />
            <NextdoorPixel />
            <RedditPixel />
            <TiktokPixel />
            <YelpPixel />
        </>
    );
}
