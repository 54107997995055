import * as React from 'react';
import type { Maybe, Settings } from '@types/gatsby-types';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query FacebookPixelQuery {
        settings {
            facebook {
                pixelId
            }
        }
    }
`;

interface FacebookPixelQuery {
    settings: Maybe<Pick<Settings, 'facebook'>>;
}

export default function FacebookPixel() {
    const { settings } = useStaticQuery<FacebookPixelQuery>(query);

    const pixelId = settings?.facebook?.pixelId;

    if (process.env.NODE_ENV === 'production' && pixelId) {
        return (
            <script
                type="text/partytown"
                id="facebook-pixel"
                dangerouslySetInnerHTML={{
                    __html: `
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.defer=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');
      `,
                }}
            />
        );
    }

    return null;
}
