import type { Maybe, BingSettings } from '@types/gatsby-types';
import { graphql, useStaticQuery } from 'gatsby';

const query = graphql`
    query BingPixelQuery {
        settings {
            bing {
                trackingPixel
            }
        }
    }
`;

type BingPixelQuery = {
    settings: Maybe<BingSettings>;
};

export default function useBingPixelId(): string | null {
    const { settings } = useStaticQuery<BingPixelQuery>(query);

    return settings?.bing?.trackingPixel ?? null;
}
